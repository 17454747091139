import React from 'react';

const redirections = () => (
<div className="cert-site">
    <div className="cert-site_bg slds-p-bottom--x-large">
        <div className="slds-container--center slds-container--medium slds-m-bottom--x-large slds-is--relative Pt(1px)">
            <div>
                <div className="Fz(32) C(black) slds-text-align--center slds-m-top--large slds-p-top--xx-large">
                    We&#39;ve Moved!
                    <div className="Fz(24) Fw(b) C(gray) slds-text-align--center slds-m-bottom--large ">
                        Salesforce Certification has moved to Trailhead<br />
                        Find all the help you need in our official Trailhead Help page
                    </div>

                    <div className="cert-site-card slds-text-align--center ">
                        <img src="https://developer.salesforce.com/resources2/certification-site/images/Icon-question.png" />
                        <div className="Fz(20) slds-m-top--small slds-m-bottom--small">
                            Trailhead Help
                        </div>

                        <div className="Fz(14) C(gray3) slds-m-top--small slds-m-bottom--small">
                            Find all the help you need in our official<br />
                            Trailhead Help page
                        </div>
                        <a className="slds-button  slds-button_brand Fz(20) slds-text-align--center" href="https://trailhead.salesforce.com/help">Trailhead Help</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

);
export default redirections;
